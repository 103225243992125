<template>
  <b-card no-body>
    <template v-if="!reportParams.id_customers">
      <b-card-body>
        <b-form-group
          label="Müşteri Arama"
          label-for="keyword"
        >
          <b-input-group>
            <b-form-input
              id="keyword"
              v-model="searchKeyword.keyword"
              placeholder="Müşteri Adı, Telefon No, Vergi No..."
              @keydown.enter="searchData"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                @click="searchData"
              >
                <FeatherIcon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-card-body>
      <b-table
        v-if="dataList.length > 0"
        class="mb-0"
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(company)="data">
          <div>{{ data.item.company }}</div>
          <div class="font-small-3 text-primary">
            {{ data.item.name }}
          </div>
        </template>
        <template #cell(country)="data">
          <div>{{ data.item.country }}</div>
          <div class="font-small-3 text-info">
            {{ data.item.city }}
          </div>
        </template>
        <template #cell(control)="data">
          <div class="text-right">
            <b-button
              variant="primary"
              size="sm"
              @click="setCustomer(data.item.id)"
            >
              Görüntüle
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-footer v-if="dataCounts > perPage">
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </template>
    <template v-else>
      <b-card-body>
        <b-row>
          <b-col>
            <div class="font-weight-bold text-primary">
              Firma Adı
            </div>
            <div class="font-weight-light">
              {{ customer.company }}
            </div>
          </b-col>
          <b-col>
            <div class="font-weight-bold text-primary">
              Telefon
            </div>
            <div class="font-weight-light">
              {{ customer.phone }}
            </div>
          </b-col>
          <b-col
            cols="12"
            md="auto"
          >
            <b-button
              variant="flat-danger"
              pill
              @click="resetData"
            >
              <FeatherIcon icon="RepeatIcon" /> Müşteri Değiştir
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </template>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BButton, BTable, BCardFooter, BPagination, BRow, BCol,
} from 'bootstrap-vue'

export default {
  name: 'CustomerSearch',
  components: {
    BPagination,
    BCardFooter,
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BTable,
    BRow,
    BCol,
  },
  props: {
    getReport: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: this.$store.state.app.perPage,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'phone',
          label: 'TELEFON',
          thClass: 'width-200',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100',
        },
      ],
      dataQuery: {
        select: [
          'customers.id AS id',
          'customers.company AS company',
          'customers.phone AS phone',
          'sectors.title AS sector',
          'cities.title AS city',
        ],
        limit: this.$store.state.app.perPage,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['customers/dataList']
    },
    dataCounts() {
      return this.$store.getters['customers/dataCounts']
    },
    searchKeyword() {
      return this.$store.getters['customers/search']
    },
    reportParams() {
      return this.$store.getters['reportCustomers/reportParams']
    },
    customer() {
      return this.$store.getters['customers/dataItem']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * this.$store.state.app.perPage
      this.getDataList()
    },
  },
  created() {
    this.resetData()
  },
  methods: {
    searchData() {
      if (this.searchKeyword.keyword) {
        this.dataQuery.or_like = {
          'customers.company': this.searchKeyword.keyword,
          'customers.phone': this.searchKeyword.keyword,
          'customers.tax_number': this.searchKeyword.keyword,
        }
        this.getDataList()
      } else {
        this.dataQuery.or_like = {}
        this.$store.commit('customers/SET_DATA_LIST', [])
        this.$store.commit('customers/SET_DATA_COUNTS', 0)
      }
    },
    getDataList() {
      this.$store.dispatch('customers/getDataList', this.dataQuery)
    },
    setCustomer(id) {
      this.reportParams.id_customers = id
      this.$store.dispatch('customers/getDataItem', id)
      this.getReport()
    },
    resetData() {
      this.searchKeyword.keyword = null
      this.$store.commit('customers/SET_DATA_LIST', [])
      this.$store.commit('customers/SET_DATA_COUNTS', 0)
      this.reportParams.id_customers = 0
    },
  },
}
</script>
