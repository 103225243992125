<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/payment-history')"
          >
            <statistic-card
              :statistic="reportData.counters.payments | toCurrency"
              icon="CheckCircleIcon"
              statistic-title="Toplam Tahsilat"
              color="primary"
              prefix="₺"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/payment-collections')"
          >
            <statistic-card
              :statistic="reportData.counters.pending | toCurrency"
              icon="ClockIcon"
              statistic-title="Vadesi Gelmemiş"
              color="warning"
              prefix="₺"
            />
          </div>
        </b-overlay>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-overlay
          :show="loading"
          rounded="sm"
          :variant="variant"
          :opacity="opacity"
          :blur="blur"
        >
          <div
            class="cursor-pointer"
            @click="$router.push('/payment-collections')"
          >
            <statistic-card
              :statistic="reportData.counters.latePayment | toCurrency"
              icon="XCircleIcon"
              statistic-title="Vadesi Geçmiş"
              color="danger"
              prefix="₺"
            />
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BOverlay, BRow, BCol } from 'bootstrap-vue'
import StatisticCard from '@/views/Reports/components/StatisticCard.vue'

export default {
  name: 'Counters',
  components: {
    StatisticCard,
    BOverlay,
    BRow,
    BCol,
  },
  data() {
    return {
      variant: 'transparent',
      opacity: 0.90,
      blur: '2px',
    }
  },
  computed: {
    loading() {
      return this.$store.getters['reportCustomers/loading']
    },
    reportData() {
      return this.$store.getters['reportCustomers/reportData']
    },
  },
}
</script>
